import { ClientErrorCode } from '../types/ErrorTypes';

export class IPv4FallbackHelper {
  private static readonly NetworkFallbackErrorCodes = new Set([
    ClientErrorCode.AuthInfoUnknown,
    ClientErrorCode.AuthUnknown,
    ClientErrorCode.GetResourceServerFailure,
    ClientErrorCode.AllocateResourceUnknown,
    ClientErrorCode.PerformResourceActionServerFailure,
    ClientErrorCode.PresessionGenericError,
  ]);

  static shouldFallbacktoIPv4(error: any): boolean {
    if (!error || error?.clientErrorCode == null) {
      return true;
    }
    return this.NetworkFallbackErrorCodes.has(error.clientErrorCode);
  }
}
