import { IMetrics } from '@bridge/IMetrics';
import {
  Dimension,
  IMetricOperation,
  ITimedMetricOperation,
  ITimedMetricOperationSummary,
  MetricName,
  MetricResult,
  Operation,
} from '@bridge/types/MetricTypes';
import { MetricsManager } from '@core/metrics/MetricsManager';
import { TimedMetricOperation } from '@core/metrics/TimedMetricOperation';
import { DimensionSet } from '@core/metrics/types';
import { identifyMetricResult } from '@core/metrics/MetricResultIdentifier';
import { SessionTimedMetricTracker } from '@core/metrics/SessionTimedMetricTracker';
import { SessionProtocols } from '@bridge/types/SessionTypes';

/*
 * Workspaces Clients publishes metrics using the following protocol:
 * Success scenario -  {Result::Error, Value::0} , {Result::Error, Value::0}
 * Error scenario -  {Result::Error, Value::0} , {Result::Fault, Value::1}
 * Failure scenario -  {Result::Error, Value::0} , {Result::Fault, Value::1}
 *
 * Metric to record time is only published in success scenario
 * Error Metric is published for handled exceptions
 * Fault Metric signifies unhandled exceptions
 */

export class Metrics implements IMetrics {
  private readonly metricsManager: MetricsManager;
  private sessionTimeMetricTracker: SessionTimedMetricTracker | undefined;
  private isApplicationOperationPublished: boolean;

  constructor(metricsManager: MetricsManager) {
    this.metricsManager = metricsManager;
    this.isApplicationOperationPublished = false;
  }

  /*
   * This operation publishes timed metric along with the error/fault for the operation
   * */
  emitMetricOperation(metricOperation: IMetricOperation, error?: any) {
    let dimensions = metricOperation.dimensions;
    if (error) {
      metricOperation.result = identifyMetricResult(
        metricOperation.operation,
        error
      );
      dimensions = this.addErrorDimensions(metricOperation.dimensions, error);
    }
    if (metricOperation.result === MetricResult.UnhandledException) {
      // this.metricsManager.publishException(error); TODO: Add this line when we get the deviceType for web-client crash
    }

    if (metricOperation.result === MetricResult.Success) {
      this.metricsManager.record(
        metricOperation.operation,
        metricOperation.metricName,
        metricOperation.getValue(),
        dimensions
      );
    }
    this.emitWithDimensions(
      metricOperation.operation,
      metricOperation.result,
      dimensions
    );
  }

  /*
   * This method is used by Web Client InSession to publish Session Login Time using wsp session context data
   * */
  emitWebSessionTimedMetricOperation(
    sessionTimedMetricSummary: ITimedMetricOperationSummary,
    error?: any
  ) {
    if (
      sessionTimedMetricSummary?.startTime === undefined ||
      sessionTimedMetricSummary?.operation !== Operation.Session
    ) {
      return;
    }

    let dimensions = sessionTimedMetricSummary.dimensions;
    const value = Date.now() - sessionTimedMetricSummary.startTime;
    if (error) {
      sessionTimedMetricSummary.result = identifyMetricResult(
        sessionTimedMetricSummary.operation,
        error
      );
      dimensions = this.addErrorDimensions(
        sessionTimedMetricSummary.dimensions,
        error
      );
    }
    if (sessionTimedMetricSummary.result === MetricResult.UnhandledException) {
      // this.metricsManager.publishException(error); TODO: Add this line when we get the deviceType for web-client crash
    }

    if (sessionTimedMetricSummary.result === MetricResult.Success) {
      this.metricsManager.record(
        sessionTimedMetricSummary.operation,
        sessionTimedMetricSummary.metricName,
        value,
        dimensions
      );
    }
    this.emitWithDimensions(
      sessionTimedMetricSummary.operation,
      sessionTimedMetricSummary.result,
      dimensions
    );
  }

  // ToDo: Remove input result variable in final CR for metrics
  emit(operation: Operation, result: MetricResult, error?: any) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    let decoratedDimensions = {} as DimensionSet;
    result = MetricResult.Success;
    if (error) {
      decoratedDimensions = this.addErrorDimensions(decoratedDimensions, error);
      result = identifyMetricResult(operation, error);
    }
    this.emitWithDimensions(operation, result, decoratedDimensions);
  }

  emitUnhandledExcpetion(operation: Operation, error: any) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    let decoratedDimensions = {} as DimensionSet;
    decoratedDimensions = this.addErrorDimensions(decoratedDimensions, error);

    this.emitWithDimensions(
      operation,
      MetricResult.UnhandledException,
      decoratedDimensions
    );
    this.metricsManager.publishException(error);
  }

  embark(
    operation: Operation,
    autoStartTimer?: boolean
  ): ITimedMetricOperation {
    return new TimedMetricOperation(operation, autoStartTimer ?? true);
  }

  embarkSessionMetric(): SessionTimedMetricTracker {
    if (!this.sessionTimeMetricTracker) {
      this.sessionTimeMetricTracker = new SessionTimedMetricTracker(
        this.embark(Operation.Session, false)
      );
    }
    return this.sessionTimeMetricTracker;
  }

  emitWithDimensions(
    operation: Operation,
    result: MetricResult,
    dimensions: DimensionSet
  ): void {
    let error = 0;
    let fault = 0;
    let unhandledException = 0;
    switch (result) {
      case MetricResult.Error:
        error = 1;
        break;
      case MetricResult.Fault:
        fault = 1;
        break;
      case MetricResult.UnhandledException:
        unhandledException = 1;
        break;
    }
    if (unhandledException === 1) {
      this.emitWithValue(
        operation,
        MetricName.UnhandledException,
        unhandledException,
        dimensions
      );
    } else {
      this.emitWithValue(operation, MetricName.Error, error, dimensions);
      this.emitWithValue(operation, MetricName.Fault, fault, dimensions);
    }
  }

  emitWithValue(
    operation: Operation,
    name: MetricName,
    value: number,
    dimensions?: DimensionSet
  ): void {
    if (!dimensions) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      dimensions = {} as DimensionSet;
    }
    this.metricsManager.record(operation, name, value, dimensions);
  }

  emitApplicationLaunch() {
    if (!this.isApplicationOperationPublished) {
      this.isApplicationOperationPublished = true;
      this.emitWithValue(Operation.Application, MetricName.Launch, 1);
    }
  }

  emitDisconnectMetrics(
    disconnectStatusCodeFromInSession: string | null,
    sessionProtocol: SessionProtocols
  ) {
    const dimensions = this.addErrorDimensionsForDisconnectOperation(
      sessionProtocol,
      disconnectStatusCodeFromInSession
    );

    this.emitWithValue(
      Operation.Session,
      MetricName.WorkspaceDisconnect,
      1,
      dimensions
    );
  }

  emitWithValueAndReason(
    operation: Operation,
    name: MetricName,
    value: number,
    error?: any
  ) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    let dimensions: DimensionSet = {} as DimensionSet;

    if (error) {
      dimensions = this.addErrorReason(dimensions, error);
    }
    this.emitWithValue(operation, name, value, dimensions);
  }

  async flush() {
    return await this.metricsManager.flush();
  }

  private addErrorDimensions(srcDimensionSet: DimensionSet, exception: any) {
    srcDimensionSet[
      Dimension.ExceptionMessage
    ] = `Message::${exception?.message} Code::${exception?.code}`;
    srcDimensionSet[Dimension.InnerExceptionType] = exception?.name;
    srcDimensionSet[Dimension.ErrorCode] = JSON.stringify(
      exception?.clientErrorCode
    );
    srcDimensionSet[Dimension.Reason] = JSON.stringify(
      exception?.clientErrorMessage
    );
    return srcDimensionSet;
  }

  private addErrorReason(srcDimensionSet: DimensionSet, exception: any) {
    srcDimensionSet[Dimension.Reason] = JSON.stringify(
      exception?.clientErrorMessage
    );
    return srcDimensionSet;
  }

  private addErrorDimensionsForDisconnectOperation(
    sessionProtocol: SessionProtocols,
    disconnectStatusCodeFromInSession?: string | null
  ) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const dimensions = {} as DimensionSet;
    dimensions.ProtocolDisconnectCode =
      disconnectStatusCodeFromInSession ?? 'None';
    dimensions.AppDisconnectAction = '';
    dimensions.ProtocolName = sessionProtocol.toString();
    return dimensions;
  }
}
